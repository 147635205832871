import styled from '@emotion/styled';
import { JackPot } from 'assets/images';
import { dimentions, sizes } from 'service';
import { mixins } from 'theme';

export const GameLayoutContainer = styled.div`
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;

  @media only screen and (orientation: landscape) and (max-width: 667px),
    (max-height: 515px) {
    .guide-left-payout {
      .modal-paytable {
        max-width: 289px;
        max-height: 662px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .modal-guide {
        transform: scale(0.62);
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 667px),
    (max-height: 515px) {
    .guide-right-payout {
      .modal-paytable {
        max-width: 289px;
        max-height: 347px;
        & > div {
          min-width: 408px !important;
          font-size: 14px;
          transform: scale(0.6);
        }
      }
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 375px),
    (max-height: 515px) {
    .guide-right-payout {
      .modal-paytable {
        & > div {
          min-width: 418px;
          transform: scale(0.5);
        }
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 667px),
    (max-height: 515px) {
    .guide-right-payout {
      .modal-paytable {
        & > div {
          width: 418px;
          transform: scale(0.5);
          position: relative;
        }
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-height: 350px) {
    .guide-right-payout {
      .modal-paytable {
        max-width: 189px;
        max-height: 230px;
        & > div {
          transform: scale(0.3);
        }
      }
    }
    .guide-left-payout {
      .modal-guide {
        transform: scale(0.4);
      }
    }
  }

  .toggle-container {
    position: relative;
    top: 9px;
  }
  .bet {
    min-width: 516px !important;
  }

  .bottom-contents-sidebet {
    bottom: 174px;
  }

  .balance-value {
    height: 33px !important;
    overflow: visible;
    position: relative;

    .background-container {
      position: absolute;
      height: 33px;
      width: 94px;
      z-index: 1;
      left: -11px;
    }
  }

  .bet-value {
    max-width: 163px;
    height: 33px !important;
    overflow: visible;
    position: relative;

    .background-container {
      position: absolute;
      height: 33px;
      width: 69px;
      z-index: 1;
      left: -11px;
    }
  }

  #bgVideo {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .header-menu {
    position: relative;
    width: 7px;
    height: 7px;
    background: #fff;
    border-radius: 50%;
    top: -9px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      background-color: inherit;
      border-radius: inherit;
    }

    &:before {
      top: 10px;
    }

    &:after {
      top: 20px;
    }
    .ant-image-img {
      visibility: hidden;
      z-index: 1;
    }
  }

  .jackpot-wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .free-star-left-wrapper {
      position: absolute;
      left: -57px;
      top: -20px;
      .free-star-left {
        position: relative;
        .free-star-image {
          width: 66px;
          height: 63px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .free-left-value {
          position: absolute;
          border-radius: 6px;
          background-color: #fd3435;
          top: -2px;
          right: 0;
          width: 19px;
          height: 18px;
          text-align: center;
          color: white;
          font-size: 12px;
        }
      }
    }
    .free-celebration-wrapper {
      position: absolute;
      left: 35px;
    }
  }
  .rolling-container {
    user-select: none;
    position: relative;
    max-width: 516px;
    min-width: 516px;
    width: 516px;
    min-height: 830px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* .pitch-outro {
      position: relative;
      z-index: 2;
    } */
    .instruction-one {
      margin-top: 153px;
      @media only screen and (max-width: 430px) {
        margin-top: 80px;
      }
    }

    .ins-container {
      position: absolute;
      width: calc(100% + 30px);
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 4;
      top: 0;
      @media only screen and (min-width: 430px) {
        min-height: 100vh;
      }
      .ins-wrapper {
        position: relative;
        width: calc(100% - 30px);
        height: 100%;
        .cricket-paytable {
          margin-right: 1rem;
          width: 479px !important;
          height: 674px !important;
          top: 6rem;
          left: 14px;
          background-size: 100% 100%;
          transform: scale(0.83);
          tbody {
            text-align: left;
          }
        }
        .btn-guide {
          z-index: 1;
          cursor: pointer;
          transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            transform 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            border-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
            box-shadow 0.45s cubic-bezier(0.25, 1, 0.33, 1);
          &:hover {
            transform: translateY(-3px);
          }
          &.guide2 {
            top: 150px;
          }
          &.guide3 {
            top: 127px;
            left: 41px;
          }
          &.guide5 {
            top: 140px;
            left: 46px;
          }
        }
      }
    }

    .rolling-header {
      width: 90%;
      z-index: 2;
      height: 85px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .game-id {
        position: absolute;
        right: 0;
        top: 5px;
        opacity: 0.7;
        font-family: 'Barlow Semi Condensed';
        font-size: 10px !important;
        font-weight: 500 !important;
        font-stretch: semi-condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.7) !important;
      }
      .balance-value {
        flex: 3;
        height: 30px;
        border-radius: 7.3px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        span.container-span {
          transform: skewX(-20deg);
          background: rgba(0, 0, 0, 0.7);
          height: 33px;
          max-width: 190px;
          flex: 1;
          text-align: center;

          div {
            font-family: 'Barlow Semi Condensed';
            font-size: 16px !important;
            transform: skewX(20deg);
            height: 33px;
            line-height: 33px;
          }
        }
        .balance-value-title {
          z-index: 2;
          text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          font-family: 'Barlow Semi Condensed';
          font-size: 13.5px;
          font-weight: bold;
          text-transform: uppercase;
          font-stretch: semi-condensed;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }
      }
      .bet-value {
        flex: 3;
        height: 30px;
        color: #f00;
        font-weight: 700;
        font-family: 'Roboto';
        border-radius: 7.3px;
        background: rgba(31, 29, 28, 0.75);
        background-size: 100% 100%;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        span {
          flex: 1;
          text-align: center;
          transform: skewX(-20deg);
          background: rgba(0, 0, 0, 0.7);
          height: 33px;

          div {
            font-family: 'Barlow Semi Condensed';
            font-size: 16px !important;
            transform: skewX(20deg);
            height: 33px;
            line-height: 33px;
          }
        }
        .bet-value-title {
          z-index: 2;
          text-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
          font-family: 'Barlow Semi Condensed';
          font-size: 13.5px;
          font-weight: bold;
          text-transform: uppercase;
          font-stretch: semi-condensed;
          font-style: normal;
          line-height: normal;
          -webkit-letter-spacing: normal;
          -moz-letter-spacing: normal;
          -ms-letter-spacing: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }
        .bet-value-pt {
          position: relative !important;
          left: -6px !important;
        }
      }
      .game-number-container {
        height: 100%;
        display: flex;
        align-items: flex-end;
      }
      i {
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    .score-container {
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 5px;
      .left-side {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.play {
          flex: 1;
        }
        .jack-bot {
          height: 70px;
          width: 100%;
          background: url(${JackPot});
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .right-side {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 10px;
      }
    }
    .round-bonus {
      position: absolute;
      width: 118px;
      text-align: center;
      top: 159px;
      right: -22px;
    }
    .bet-text-animation {
      position: absolute;
      top: ${dimentions.additionalTop}px;
      font-size: 40px;
      padding: 0 33px;
      color: #ffff45;
      background: linear-gradient(
        360deg,
        #fbe741 25%,
        #dba323 53%,
        #fbe741 72%
      );
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      font-weight: 900;
      font-family: 'Roboto';
      text-align: center;
      filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
      text-transform: uppercase;
      z-index: 1001;
    }
    .bottom-guide-container {
      position: absolute;
      bottom: 40px;
      right: 0;
    }
  }

  .cricket-paytable {
    margin-right: 1rem;
  }

  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.ipadHeight}px) {
    .cricket-paytable {
      margin-right: 0;
      max-height: 662px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    padding-bottom: 0px;
    .rolling-container {
      padding-top: 10px;
      .rolling-header {
        .game-id {
          top: 5px;
        }
        .jack-bot {
          width: 160px;
          span {
            font-size: 16px !important;
          }
        }
        .coin {
          width: 155px;
          height: 55px;
          background-size: 100% 100%;
        }
        .game-number-container {
          .game-number {
            font-size: 9px !important;
          }
        }
      }
      .score-container {
        width: ${sizes.mainWidthM}px;
        height: 50px;
        margin-top: 0px;
        .jack-bot {
          width: ${sizes.mainWidthM}px;
          background-size: 100% 100%;
          span {
            font-size: 20px !important;
          }
        }
        .scores {
          .score-card {
            width: 120px;
          }
        }
        .left-side {
          .coin {
            width: 160px;
          }
        }
      }

      .bet-text-animation {
        font-size: 22px !important;
      }
    }
  }

  @media (max-height: 1100px) {
    align-items: center;
  }
`;

export const StrikeAnimationContainer = styled.div`
  position: absolute;
  top: 480px;
  z-index: ${(props) => (props.show777 ? 1 : 0)};
  display: ${(props) => (props.show777 ? 'block' : 'none')};
  transform: translateX(-45px);
`;

export const LineWinAnimationContainer = styled.div`
  ${(props) =>
    !props.show
      ? `
    width: 0;
    height: 0;
    opacity: 0;
  `
      : ''}
`;

export const JackpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .full-card {
    width: 110px;
  }
`;

export const BottomContents = styled.div`
  position: relative;
  width: 98%;
  max-width: 460px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 90px;
  height: 150px;
`;

export const SideBetContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  &::nth-child(2) {
    justify-content: end;
  }
`;

export const BackgroundImage = styled.img`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

export const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-left: 15px;
`;

export const BgContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
`;
